import React, {useEffect} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/styles.css";

// const VERSION_STRING = `/v${process.env.REACT_APP_TICKET_VERSION}`;

export default function SubmitIssue() {
    
    useEffect(()=>{
        
        const script = document.createElement('script');

        script.src = "https://insyncinsurance.youtrack.cloud/static/simplified/form/form-entry.js";
        script.id = "52d9bf25-7174-441c-983d-53b712887e68";
        script.dataset.ytUrl = "https://insyncinsurance.youtrack.cloud";
        script.dataset.theme = "light";
        script.dataset.lang = "en";
        script.async = true;

        const script_container_exists = (document.querySelector("#script-container") ? true : false);
        const script_form_exists = (document.querySelector(`#script-container script[id='${script.id}']`) ? true : false);

        if (script_container_exists && !script_form_exists) document.getElementById("script-container").appendChild(script);
        
    }, [])

    return(
        <main>
            <Navbar />
            <section>

                {/* <div id='alert-danger' className="alert alert-danger rounded-0 border-0 text-center" role="alert" style={{display: "none"}}>
                    This is a danger alert—check!
                </div> */}

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
                    <div id="script-container"></div>
                </div>
                
            </section>
            <Footer/>
        </main>
    )
}
