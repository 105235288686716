import React from 'react';
import {useNavigate} from "react-router-dom";
import "./css/mobile_menu.css";
import axios from "axios";
import * as Sentry from "@sentry/react";

// const VERSION_STRING = `/v${process.env.REACT_APP_TICKET_VERSION}`;

const MobileMenu = ({isAdminLoggedIn})=>{
    
    const navigate = useNavigate();
    const REDIRECT_URI = "http://127.0.0.1:3000/admin";
    const youtrackUrl = 'https://insyncinsurance.youtrack.cloud';


    const handleSearch = (e) => {
        e.preventDefault();
        const query = e.target.elements.search.value;

        navigate(`/existing/${query}`);
        hideMobileMenu();

    }

    function hideMobileMenu(){
        document.getElementById('mobile-menu').style.opacity = "0";
        document.getElementById('mobile-menu').style.visibility = "hidden";
    }
    function showMobileMenu(){
        document.getElementById('mobile-menu').style.visibility = "visible";
        document.getElementById('mobile-menu').style.opacity = "1";
    }


    function handleLogout(){
        
        axios.post(`${youtrackUrl}/hub/api/rest/oauth2/interactive/login?response_type=token&client_id=${process.env.REACT_APP_YOUTRACK_SERVICE_ID}&redirect_uri=${REDIRECT_URI}&scope=${process.env.REACT_APP_YOUTRACK_SERVICE_ID}%20Upsource%20TeamCity%20YouTrack%2520Slack%2520Integration%200-0-0-0-0&request_credentials=required`, {
            username: '',
            password: ''
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.accessToken}`,
            }
        })
        .then((response) => {
            // console.log(response);
            localStorage.clear();
            window.location.href = '/admin'
        })
        .catch((error) => {
            Sentry.captureException(error);
            localStorage.clear();
            window.location.href = '/admin'
        })

    }

    return (
        <>
            {/* Mobile menu button */}

            <div className='d-flex'>
                <div id="mobile-logo" className='d-flex me-auto ms-3 my-3'>
                    <img src="/Insync-Logo-New.70c5bd48.png" alt="Insync Insurance" height="50"/>
                </div>

                <div id='mobile-menu-container' className='d-flex my-auto mx-4 float-right flex-end'>

                    {isAdminLoggedIn ? <button className='btn no-bg relative' style={{
                        right:30,
                        border: "2px solid #dadada"
                    }} onClick={handleLogout}>Logout</button> : ""}

                    <button id='menu-btn' style={{
                        display: "none",
                        outline: "none",
                        border: 0,
                        background: "none",
                        clear: "both"
                    }} onClick={showMobileMenu}>

                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                            <g xmlns="http://www.w3.org/2000/svg" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ff7401" stroke="none">
                                <path d="M192 4459 c-32 -13 -71 -39 -101 -69 -160 -160 -103 -452 104 -529 l56 -21 2309 0 2309 0 56 21 c260 97 260 501 0 598 l-56 21 -2312 -1 c-2299 0 -2312 0 -2365 -20z"/>
                                <path d="M192 2859 c-32 -13 -71 -39 -101 -69 -160 -160 -103 -452 104 -529 l56 -21 2309 0 2309 0 56 21 c260 97 260 501 0 598 l-56 21 -2312 -1 c-2299 0 -2312 0 -2365 -20z"/>
                                <path d="M192 1259 c-32 -13 -71 -39 -101 -69 -160 -160 -103 -452 104 -529 l56 -21 2309 0 2309 0 56 21 c260 97 260 501 0 598 l-56 21 -2312 -1 c-2299 0 -2312 0 -2365 -20z"/>
                            </g>
                        </svg>
                    </button>

                    <div id='mobile-menu' style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#404042",
                        width: "100%",
                        minHeight: "250px",
                        position: "fixed",
                        borderBottom: "3px solid #ff7401",
                        left: 0,
                        top: 0,
                        visibility: "hidden",
                        opacity: 0,
                        zIndex: 10
                    }}>
                        
                        <div className='d-flex'>
                            <div id='links' className='my-3 w-100'>
                                <ul>
                                    <a href={"/"} rel="noopener noreferrer"><li>HOME</li></a>
                                    <a href={"/submit-issue"} rel="noopener noreferrer"><li>SUBMIT TICKET</li></a>
                                    <a href={"/knowledge-base"} rel="noopener noreferrer"><li>KNOWLEDGE BASE</li></a>
                                    <a href={"/issues"} rel="noopener noreferrer"><li>ALL ISSUES</li></a>
                                    <a href="tel:01200 309 516" rel="noopener noreferrer"><li>CALL US: &nbsp;01200 309 516</li></a>
                                </ul>
                            </div>
                            
                            {/* Close Button */}
                            <button className='my-3 mx-4 float-right' style={{
                                outline: "none",
                                border: 0,
                                background: "none",
                                height: "max-content"
                            }} onClick={hideMobileMenu}>

                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ff7401" stroke="none">
                                        <path d="M132 5094 c-87 -43 -132 -121 -132 -226 0 -41 7 -79 21 -108 15 -35 258 -283 1097 -1122 l1077 -1078 -1081 -1082 c-1201 -1203 -1120 -1112 -1112 -1246 7 -135 95 -223 230 -230 134 -8 43 -89 1246 1112 l1082 1081 1083 -1081 c1202 -1201 1111 -1120 1245 -1112 135 7 223 95 230 230 8 134 89 43 -1112 1245 l-1081 1083 1081 1083 c1201 1202 1120 1111 1112 1245 -7 135 -95 223 -230 230 -134 8 -43 89 -1246 -1112 l-1082 -1081 -1078 1077 c-839 839 -1087 1082 -1122 1097 -67 31 -161 28 -228 -5z"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        
                        <div className='w-100 mx-auto px-4 pb-4'>
                            <form className="d-flex mx-auto px-2" onSubmit={handleSearch}>
                                <input className="form-control me-2" name="search" type="search" placeholder="Search" aria-label="Search"/>
                                <button id="search-btn-mobile" className="btn btn-outline-success" type="submit">Search</button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileMenu;