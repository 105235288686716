import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

// const VERSION_STRING = `/v${process.env.REACT_APP_TICKET_VERSION}`;

export default function Footer() {
    return(
        <footer className="d-flex flex-wrap align-items-center py-2 my-4 border-top m-3">
            <div className="col-md-4 d-flex flex-column p-2">
                <a href={"/"} className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1 p-2" style={{width:"max-content"}}>
                    <img src="/Insync-Logo-New.70c5bd48.png" alt="Insync Insurance"
                         height="50"/>
                </a>
                <div className="p-2">
                    <span className="d-block mb-3 mb-md-0 text-muted">Insync Insurance Solutions Ltd</span>
                    <span className="d-block mb-3 mb-md-0 text-muted">9 Albany Park, Cabot Lane</span>
                    <span className="d-block mb-3 mb-md-0 text-muted">Poole, Dorset BH17 7BX</span>
                </div>
                <a href="tel:01200 309 516" className="mb-3 mb-md-0 text-muted">t. 0330 124 0730 </a>
                <a href="mailto:hello@insyncinsurance.co.uk" className="mb-3 mb-md-0 text-muted">e.
                    hello@insyncinsurance.co.uk</a>
            </div>

            <div className="col-md-7 d-flex align-items-center">
                <span className="mb-3 mb-md-0 text-muted">© Insync Insurance Solutions Ltd 2018 All rights reserved. Insync Insurance Solutions Ltd is authorised & regulated by the Financial Conduct Authority where our reference number is 766691. Our registered office is 7th Floor, Corn Exchange, 55 Mark Lane, London, EC3R 7NE and we are registered in England under company number 08810662. Should you have cause to complain, and you are not satisfied with our response to your complaint, you may be able to refer it to the Financial Ombudsman Service, which can be contacted as follows: The Financial Ombudsman Service Exchange Tower, London, E14 9SR | Tel: 0800 023 4567 or 01200 309516 | www.financial-ombudsman.org.uk |Terms of Business</span>
            </div>
        </footer>
    );
}