import React, {useState} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useDropzone} from 'react-dropzone';
import DatePicker from 'react-date-picker'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import "./css/styles.css";
import "./css/DatePicker.css";
import * as Sentry from "@sentry/react";

// const VERSION_STRING = `/v${process.env.REACT_APP_TICKET_VERSION}`;

export default function ChangeRequest() {
    
    const [value, onChange] = useState(new Date());
    console.log(value)
    // due date + 2 weeks
    // const [requestDateValue, setRequestDateValue] = useState(value);
    const [effectiveDateValue, setEffectiveDateValue] = useState(null);

    const [dueValue, dueOnChange] = useState(null); // useState(new Date(value.getTime() + 12096e5));
    const navigate = useNavigate();
    const [buttonText, setButtonText] = useState("Submit");

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const [fileList, setFileList] = useState([]);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles)=>{
            // console.log(acceptedFiles)
            let newFiles = [];
            acceptedFiles.map((file)=>{
                newFiles.push(file)
                return file;
            })

            setFileList(newFiles);
        }
    });
    
    function assembleFiles(acceptedFiles){

        return fileList.map(file => (
            <li className='d-block' key={file.path}>

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className="glyph_f986"><g><path d="M13.62,8.07,6.48,1l-1,1,7.13,7.07a3.19,3.19,0,0,1,1,2A2.37,2.37,0,0,1,12.93,13a2.43,2.43,0,0,1-1.87.65A3.21,3.21,0,0,1,9,12.65l-1-.94h0L2.7,6.42a1,1,0,0,1,0-1.36,1,1,0,0,1,1.38,0l6.76,6.7,1-1L5,4.1a2.41,2.41,0,0,0-3.36,0,2.35,2.35,0,0,0,0,3.33L2.27,8h0L8,13.63A4.63,4.63,0,0,0,11,15h.26A3.68,3.68,0,0,0,15,11,4.54,4.54,0,0,0,13.62,8.07Z"></path></g></svg>
                    
                <span className='ms-2'>{file.path}</span> <button className='btn border-0' onClick={(event)=>{
                    event.preventDefault();
                    setFileList(fileList.filter((f)=>f !== file))
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="8.000000pt" height="8.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ff7401" stroke="none">
                            <path d="M132 5094 c-87 -43 -132 -121 -132 -226 0 -41 7 -79 21 -108 15 -35 258 -283 1097 -1122 l1077 -1078 -1081 -1082 c-1201 -1203 -1120 -1112 -1112 -1246 7 -135 95 -223 230 -230 134 -8 43 -89 1246 1112 l1082 1081 1083 -1081 c1202 -1201 1111 -1120 1245 -1112 135 7 223 95 230 230 8 134 89 43 -1112 1245 l-1081 1083 1081 1083 c1201 1202 1120 1111 1112 1245 -7 135 -95 223 -230 230 -134 8 -43 89 -1246 -1112 l-1082 -1081 -1078 1077 c-839 839 -1087 1082 -1122 1097 -67 31 -161 28 -228 -5z"/>
                        </g>
                    </svg>
                </button>
            </li>
        ));
    }

    const files = assembleFiles(acceptedFiles);


    // Endpoint Configuration
    const BASE_URL = "https://insyncinsurance.youtrack.cloud";
    const API_URL = `${BASE_URL}/api/issues?fields=id,idReadable`;
    const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;


    function handleSubmit(e) {
        e.preventDefault()
        const title = e.target.elements.title.value;
        const description = e.target.elements.description.value;
        const priority = e.target.elements.priority.value;
        const requestType = e.target.elements.type.value;
        const reason = e.target.elements.reason.value;
        const requestDate = value.toUTCString();
        const dueDate = (dueValue ? dueValue.toUTCString() : null);
        const initiator = e.target.elements.initiator.value;
        const sponsor = e.target.elements.sponsor.value;
        const submitButton = document.getElementById("submit-btn");
        const spinner = document.getElementById("loading-spinner");
        const effectiveVersion = document.querySelector("select[name=effectiveVersion]").value;
        
        const requestersEmailInput = document.getElementById("email");


        setButtonText("Submitting...");

        // projects
        const projects = {
            "AXIS Aesthetics": "0-4",
            "AXIS Clinical":"0-16",
            "AXIS Professionals Combined": "0-3",
            "AXA Professional Indemnity": "0-15",
            "AXIS Property Owners": "0-1",
            "Beazley Aesthetics": "0-7",
            "Change Request": "0-5",
            "Faraday Liability": "0-2",
            "Insync Beauty": "0-18",
            "General": "0-6",
            "NBS": "0-19",
            "PBIS": "0-12",
            "Test": "0-11",
            "Cover My Tools": "0-14",
            "Insync Insurance": "0-36"
        }

        // Issue Form Fields
        const YouTrack_Issue_Data = {

            project: {"id": projects["Insync Insurance"]},
            summary: title,
            description: description,
            customFields: [
                {
                    "name": "Priority", "$type": "SingleEnumIssueCustomField", "value": {"name": priority}
                },
                {
                    "name": "Type", "$type": "SingleEnumIssueCustomField", "value": {"name": requestType}
                },
                {
                    "name": "Initiator", "$type": "SimpleIssueCustomField", "value": initiator
                },
                {
                    "name": "All related emails", "$type": "SimpleIssueCustomField", "value": requestersEmailInput.value
                },
                {
                    "name": "Reason for Change", "$type": "SimpleIssueCustomField", "value": reason
                },
                {
                    "name": "Request Date", "$type": "DateIssueCustomField", "value": (new Date(requestDate)).getTime()
                },
                {
                    "name": "Due Date", "$type": "DateIssueCustomField", "value": (dueDate ? (new Date(dueDate)).getTime() : null)
                },
                {
                    "name": "Effective Date", "$type": "DateIssueCustomField", "value": (effectiveDateValue && document.querySelector("input[name=isBackdated]:checked").value === "yes" ? (new Date(effectiveDateValue)).getTime() : null)
                },
                {
                    "name": "Change Version", "$type": "SingleEnumIssueCustomField", "value": (!effectiveDateValue && document.querySelector("input[name=isBackdated]:checked").value === "no" ? {"name": effectiveVersion} : null)
                },
                {
                    "name": "Sponsor", "$type": "SimpleIssueCustomField", "value": sponsor
                }
            ]
        }

        // console.log(YouTrack_Issue_Data);

        // Post Request
        var AxiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: API_URL,
            headers: { 
              'Accept': 'application/json', 
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${ACCESS_TOKEN}`
            },
            data : JSON.stringify(YouTrack_Issue_Data)
        };
        
        
        // validate requester's email before submitting
        if (!validateEmail(requestersEmailInput.value)){
            toggleInvalidEmailLabel(validateEmail(requestersEmailInput.value));
            setButtonText("Submit");
            return false;
        }
        else {
            axios(AxiosConfig).then(function (response) {

                let responseID = response.data.id;
                let readableID = response.data.idReadable;

                if (acceptedFiles.length > 0) {
                    handleUpload(acceptedFiles, responseID).then(r => console.log(r))
                }

                navigate(`/thanks`, { state: { readableID, title} });

            })
            .catch(function (error) {
                console.log(error.response.data);
                document.getElementById("alert-danger").innerText = error.response.data.error_description;
                document.getElementById("alert-danger").style.display = "block";
                document.getElementById("alert-danger").scrollIntoView();
                spinner.style.display = "none";
                submitButton.style.display = "block";
                setButtonText("Submit")
            });
        }

    }

    async function handleUpload(files, fileIssueID) {

        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post(`${BASE_URL}/api/issues/${fileIssueID}/attachments`,
                formData, {
                    headers: { 
                      'Accept': 'application/json', 
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${ACCESS_TOKEN}`
                    },
                });
                console.log(response.data);
            } catch (error) {
                Sentry.captureException(error);
            }
        }
    }

    function toggleIsBackdated(event){
        
        if (event.target.value === "yes"){
            document.getElementById("effectiveDate").style.display = "block";
            document.getElementById("effectiveVersion").style.display = "none";
            setEffectiveDateValue(new Date())
        }
        else {
            document.getElementById("effectiveDate").style.display = "none";
            document.getElementById("effectiveVersion").style.display = "block";
            setEffectiveDateValue(null)
        }
    }

    function validateEmail(email) {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // Test the email against the regex and email must be an insync insurance email address
        return (emailRegex.test(email) && email.split("@")[1].toLowerCase() === "insyncinsurance.co.uk");
    }

    function toggleInvalidEmailLabel(condition){
        const emailInput = document.getElementById("email");
        const invalidEmailLabel = document.getElementById("invalid-email");

        if (condition){ // true
            emailInput.classList.remove("border-danger");
            invalidEmailLabel.classList.add("d-none");
            invalidEmailLabel.classList.remove("d-block");
        }
        else {
            emailInput.classList.add("border-danger");
            invalidEmailLabel.classList.add("d-block");
            invalidEmailLabel.classList.remove("d-none");
            emailInput.focus();
        }
    }
    
    function UTMConverter(query){
        return (window.location.search && window.location.search.includes(`${query}=`) ? decodeURIComponent(window.location.search.split(`${query}=`)[1].split("&")[0]) : "")
    }

    return(
        <main>
            <Navbar/>
            <section>
                
                <div id='alert-danger' className="alert alert-danger rounded-0 border-0 text-center" role="alert" style={{display: "none"}}>
                    This is a danger alert—check!
                </div>

                <h1 className="text-center display-4 p-5">New Change Request</h1>
                <div className="d-flex gap-5 justify-content-center col-lg-8 mx-auto" id="app">

                    <form onSubmit={handleSubmit} encType="multipart/form-data" className='col-sm-8 cr-form'>

                        <FloatingLabel
                            controlId="title"
                            label={<div className="label-content">Change Request Summary <span className="red-asterisk">*</span></div>}
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="A brief summary"  name="title" required={true}/>
                        </FloatingLabel>

                        <FloatingLabel controlId="description" label={<div className="label-content" style={{paddingTop:0, paddingBottom:0}}>Description of Change <span className="red-asterisk">*</span></div>} style={{border: "1px solid #dee2e6", borderRadius:8}}>
                            <Form.Control
                                as="textarea"
                                placeholder="Description of Change"
                                style={{ height: '100px', paddingTop:0, boxShadow: "none", border:0 }}
                                name="description"
                                required={true}
                            />
                        </FloatingLabel>

                        <br/>

                        <FloatingLabel controlId="reason" label={<div className="label-content" style={{paddingTop:0, paddingBottom:0}}>Reason for Change <span className="red-asterisk">*</span></div>} style={{border: "1px solid #dee2e6", borderRadius:8}}>
                            <Form.Control
                                as="textarea"
                                placeholder="Reason for Change"
                                style={{ height: '100px', paddingTop:0, boxShadow: "none", border:0 }}
                                name="reason"
                                required={true}
                            />
                        </FloatingLabel>

                        <br/>

                        <div className='d-flex'>

                            {/* <div className='me-3'>
                                <label htmlFor="requestDate">{<div className="label-content">Request Date<span className="red-asterisk">*</span></div>}</label>
                                <br/>
                                <DatePicker onChange={(dateValue)=>{
                                    console.log(dateValue)
                                    if (dateValue){
                                        setRequestDateValue(dateValue)
                                        onChange(dateValue)
                                    }
                                    else {
                                        setRequestDateValue(null)
                                    }
                                }} value={requestDateValue} format={"dd/MM/y"} name="requestDate"/>
                            </div> */}
                            
                            <div className='me-3'>
                                <label htmlFor="due-date">{<div className="label-content">Due Date<span className="red-asterisk"></span></div>}</label>
                                <br/>
                                <DatePicker format={"dd/MM/y"} name="dueDate" onChange={dueOnChange} value={dueValue}/>
                            </div>

                        </div>

                        <br/>
                        
                        <div className='d-flex flex-column mt-4'>
                           
                            <div className='d-flex'>
                                <span>Does this change need to be available for backdated policies?</span>
                                <span className="red-asterisk">*</span>
                            </div>
                            
                            <div className='d-flex my-2'>

                                <Form.Check
                                    type="radio"
                                    label="Yes"
                                    name="isBackdated"
                                    className='me-4'
                                    value="yes"
                                    required
                                    onChange={toggleIsBackdated}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="isBackdated"
                                    className='me-4'
                                    value="no"
                                    defaultChecked
                                    required
                                    onChange={toggleIsBackdated}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Don't Know"
                                    name="isBackdated"
                                    className='me-4'
                                    value="dontknow"
                                    defaultChecked
                                    required
                                    onChange={toggleIsBackdated}
                                />
                            </div>
                            
                            <div id='effectiveDate' className='mt-3' style={{display:"none"}}>
                                <label htmlFor="effectiveDate">{<div className="label-content">Effective Date of Change<span className="red-asterisk">*</span></div>}</label>
                                <br/>
                                <DatePicker onChange={(dateValue)=>{
                                    
                                    if (dateValue){
                                        setEffectiveDateValue(dateValue)
                                        onChange(dateValue)
                                    }
                                    else {
                                        setEffectiveDateValue(null)
                                    }
                                }} value={effectiveDateValue} format={"dd/MM/y"} name="effectiveDate"/>
                            </div>
                            
                        </div>

                        <br />

                        <div id='effectiveVersion' style={{display:"block"}}>
                           
                            <div className='d-flex'>
                                <span>What version should effect this change?</span>
                                <span className="red-asterisk">*</span>
                            </div>
                            
                            <div id='effectiveVersion' className='my-3'>
                                <Form.Select name='effectiveVersion' required>
                                    <option>Current Version</option>
                                    <option>New Version</option>
                                </Form.Select>
                            </div>
                            
                        </div>

                        <br />

                        <FloatingLabel controlId="type" label={<div className="label-content">Request Type <span className="red-asterisk">*</span></div>}>
                            <Form.Select aria-label="Floating label select example" name="type" required={true}>
                                <option value="Change Request">Change Request</option>
                                <option value="Epic">Epic</option>
                                <option value="User Story">User Story</option>
                                <option value="Task">Task</option>
                            </Form.Select>
                        </FloatingLabel>

                        <br/>

                        <FloatingLabel controlId="priority" label={<div className="label-content">Priority <span className="red-asterisk">*</span></div>}>
                            <Form.Select aria-label="Floating label select example" name="priority" required={true}>
                                <option>Minor</option>
                                <option>Normal</option>
                                <option>Major</option>
                                <option>Critical</option>
                            </Form.Select>
                        </FloatingLabel>

                        <br/>

                        {/* <div>
                            <label htmlFor="due-date">{<div className="label-content">Due Date<span className="red-asterisk">*</span></div>}</label>
                            <br/>
                            <DatePicker format={"dd/MM/y"} name="dueDate" onChange={dueOnChange} value={dueValue}/>
                        </div> */}

                        <FloatingLabel
                            controlId="initiator"
                            label={<div className="label-content">Initiator <span className="red-asterisk">*</span></div>}
                            className="mb-3"
                        >
                            <Form.Control name="initiator" type="text" placeholder="Jane Doe" required={true}/>
                        </FloatingLabel>
                        
                        <br />

                        <span id='invalid-email' className='text-danger d-none'>Requester's email must be an Insync Insurance email (<i className='text-secondary'>***@insyncinsurance.co.uk</i>)</span>

                        <FloatingLabel
                            controlId="email"
                            label={<div className="label-content">Requester's Email <span className="red-asterisk">*</span></div>}
                            className="mb-3"
                        >
                            <Form.Control name="email" type="email" placeholder="jane.doe@insyncinsurance.co.uk" required={true} onInput={(event)=>{
                                let inputValue = event.target.value;

                                if (!validateEmail(inputValue)){
                                    toggleInvalidEmailLabel(validateEmail(inputValue));
                                    return false;
                                }
                                else {
                                    toggleInvalidEmailLabel(validateEmail(inputValue));
                                }
                            }} defaultValue={UTMConverter("email")}/>
                        </FloatingLabel>

                        <br />
                        
                        <FloatingLabel
                            controlId="sponsor"
                            label={<div className="label-content">Sponsor <span className="red-asterisk">*</span></div>}
                            className="mb-3"
                        >
                            <Form.Control name="sponsor" type="text" placeholder="Jane Doe" required={true}/>
                        </FloatingLabel>
                        
                        <br/>
                        <div>
                            <label htmlFor="related-files">Related Files</label>
                            <br />
                            <section className="dropzone-container">
                                <div style={baseStyle} {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>Drop files here, or click to select</p>
                                </div>
                                <aside className='mt-3'>
                                    <ul className='ps-0'>{files}</ul>
                                </aside>
                            </section>
                        </div>
                        <br/>
                        <button id="submit-btn" type="submit" className="btn btn-primary">{buttonText}</button>
                        <Spinner id="loading-spinner" animation="border" variant="primary" style={{display: "none"}} />
                    </form>
                </div>
            </section>
            <Footer/>
        </main>
    )
}
