import React, {useEffect, useRef, useState} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge } from 'react-bootstrap';
import {useParams} from "react-router-dom";
import axios from "axios";
import LetteredAvatar from 'react-lettered-avatar';
import "./css/styles.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from "react-bootstrap/Spinner";
import ReactMarkdown from 'react-markdown';
import {useDropzone} from 'react-dropzone';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import * as Sentry from "@sentry/react";

export default function Existing() {

    const priorityColours = {
        "Minor": "success",
        "Normal": "success",
        "Major": "warning",
        "Critical": "danger",
        "Show-stopper": "danger",
        "None Specified": "secondary",
        "Discretionary": "success",
        "Mandatory": "warning",
        "Keeping The Lights On (KTLO)": "danger",
    }

    const stateColours = {
        "Open": "info",
        "In Progress": "warning",
        "Testing": "warning",
        "Done": "success",
        "To be discussed": "info",
        "Awaiting Feedback": "info",
        "Reopened": "danger",
        "Can't Reproduce": "danger",
        "Won't fix": "danger",
        "Incomplete": "danger",
        "Verified": "success",
        "Duplicate": "danger",
    }

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const [fileList, setFileList] = useState([]);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles)=>{
            let newFiles = [];
            acceptedFiles.map((file)=>{
                newFiles.push(file)
                return file;
            })

            setFileList(newFiles);
        }
    });
    
    function assembleFiles(acceptedFiles){

        return fileList.map(file => (
            <li className='d-block' key={file.path}>

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="glyph_f986"><g><path d="M13.62,8.07,6.48,1l-1,1,7.13,7.07a3.19,3.19,0,0,1,1,2A2.37,2.37,0,0,1,12.93,13a2.43,2.43,0,0,1-1.87.65A3.21,3.21,0,0,1,9,12.65l-1-.94h0L2.7,6.42a1,1,0,0,1,0-1.36,1,1,0,0,1,1.38,0l6.76,6.7,1-1L5,4.1a2.41,2.41,0,0,0-3.36,0,2.35,2.35,0,0,0,0,3.33L2.27,8h0L8,13.63A4.63,4.63,0,0,0,11,15h.26A3.68,3.68,0,0,0,15,11,4.54,4.54,0,0,0,13.62,8.07Z"></path></g></svg>

                <span className='ms-2'>{file.path}</span> <button className='btn border-0' onClick={(event)=>{
                    event.preventDefault();
                    setFileList(fileList.filter((f)=>f !== file))
                }}>

                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="8.000000pt" height="8.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ff7401" stroke="none">
                            <path d="M132 5094 c-87 -43 -132 -121 -132 -226 0 -41 7 -79 21 -108 15 -35 258 -283 1097 -1122 l1077 -1078 -1081 -1082 c-1201 -1203 -1120 -1112 -1112 -1246 7 -135 95 -223 230 -230 134 -8 43 -89 1246 1112 l1082 1081 1083 -1081 c1202 -1201 1111 -1120 1245 -1112 135 7 223 95 230 230 8 134 89 43 -1112 1245 l-1081 1083 1081 1083 c1201 1202 1120 1111 1112 1245 -7 135 -95 223 -230 230 -134 8 -43 89 -1246 -1112 l-1082 -1081 -1078 1077 c-839 839 -1087 1082 -1122 1097 -67 31 -161 28 -228 -5z"/>
                        </g>
                    </svg>
                </button>
            </li>
        ));
    }

    const files = assembleFiles(acceptedFiles);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { issueID } = useParams();
    const [data, setData] = useState(null);
    const [commentSection, setCommentSection] = useState(null);
    let commentsArray = useRef([]);

    
    // Endpoint Configuration
    const BASE_URL = "https://insyncinsurance.youtrack.cloud";
    const API_URL = `${BASE_URL}/api/issues`;
    const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
    const YT_QUERY = "fields=$type,id,project(id,name),summary,description,priority,state,asignee,requester,created,updated,resolved,attachments(id,name,url,thumbnailURL,base64Content,extension,created,updated),customFields(name,value(name,isResolved,id,minutes,presentation,text))";
    
    
    useEffect(() => {
        
        async function fetchData() {

            try {
                
                const response = await axios.get(`${API_URL}/${issueID}?${YT_QUERY}`, {
                    headers: { 
                      'Accept': 'application/json', 
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${ACCESS_TOKEN}`
                    },
                }).then(async (issues)=>{
                    
                    for (var data of issues.data.customFields){

                        issues.data[data.name.toLowerCase()] = data.value && !Array.isArray(data.value)
                            && typeof data.value === "object" ? data.value.name
                            : (data.value !== null ? data.value : "Unassigned");
                            
                        if (data.name.toLowerCase() === "state" && "isResolved" in data.value){
                            issues.data["isResolved"] = data.value.isResolved;
                        }
                        if (data.name.toLowerCase() === "spent time"){
                            issues.data["spent time"] = (data.value ? data.value.id: null);
                        }
                        if (data.name.toLowerCase() === "estimation"){
                            issues.data["estimation"] = (data.value ? data.value.id : null);
                        }
                        if (data.name.toLowerCase() === "closed reason"){
                            issues.data["closed reason"] = (data.value ? data.value.text : "null");
                        }
                    }

                    try {

                        // filter assignee
                        let assignee = issues.data["assignee"].filter((assignee)=>{
                            if (assignee && "name" in assignee) return assignee.name
                            else return false
                        })

                        issues.data["number_in_project"] = parseInt(issueID.split('-')[1]);
                        issues.data["id_readable"] = issueID;
                        issues.data["assignee"] = assignee
                        issues.data["project"] = issues.data["project"].name;
                    }
                    catch (error){
                        Sentry.captureException(error);
                    }


                    // Fetch comments

                    let issueComments = await axios.get(`${API_URL}/${issueID}/comments?fields=author(name),attachments(id,name,url,thumbnailURL,base64Content,extension,created,updated),deleted,text(name,value),created`, {
                        headers: {
                          'Accept': 'application/json', 
                          'Content-Type': 'multipart/form-data',
                          'Authorization': `Bearer ${ACCESS_TOKEN}`
                        },
                    }).then((commentList)=>{

                        return {

                            comments: commentList.data.map((value)=>{

                                if (!value.deleted){ // if comment is not deleted
                                    
                                    return ({
                                        author: value.author.name,
                                        comment: value.text,
                                        attachments: value.attachments,
                                        posted: {
                                            date: new Date(value.created).toDateString().slice(0,10),
                                            time: new Date(value.created).toLocaleTimeString()
                                        }
                                    })
                                }
                                else return null;

                            }).filter((a)=>a)
                        }
                    });

                    issues.data["comments"] = [issueComments];
                    return issues;

                }).catch(error=>{
                    Sentry.captureException(error);
                })

                
                if (response){
                    setData(response.data);
                }
                else {
                    setData(null)
                }

                commentsArray.current = response.data.comments;
                
                commentsArray.current = commentsArray.current ? commentsArray.current.reduce((acc, val) => {
                    if (!val) return acc;
                    return acc.concat(val.comments);
                }, []) : [];

                commentsArray.current = commentsArray.current.map(({ author, comment, posted: { date, time }, attachments }) => {
                    if (author === "Insync Reporter") {
                        const splitText = comment.split(":");
                        if (splitText.length > 1) {
                            author = splitText[0];
                            comment = splitText.slice(1).join(":").trim();
                        }
                    }
                    return [author, comment, date, time, attachments];
                });
                commentsArray.current.reverse();


                setCommentSection(commentsArray.current.map((comment, index) =>
                    <div className="comment" key={index}>
                        <div className="comment-author-ava"><LetteredAvatar name={comment[0]}/></div>
                        <div className="comment-body">
                            <ReactMarkdown className="comment-text">{comment[1]}</ReactMarkdown>
                            <div className="comment-footer"><span className="comment-meta">{comment[0]} - {comment[2]} {comment[3]}</span></div>
                        </div>

                        <div className={`${comment[4].length > 0 ? "d-flex flex-column" : "d-none"} comment-attachments mt-4 mb-5`}>
                            
                            <h5 style={{
                                fontSize: 18
                            }} className="text-secondary mx-2">Attachments</h5>

                            <div className='d-flex flex-wrap'>
                                {(()=>{
                                    
                                    const Files = [];
                                    const ImageExtentions = ["png","jpg","jpeg","gif"];

                                    comment[4].map((file, i)=>{
                                            
                                            Files.push(<div className='d-block float-left relative p-3 m-2' key={i} style={{
                                                background: (ImageExtentions.includes(file.extension.toLowerCase()) ? "#e7efff" : "#e5f6e3")
                                            }}>

                                                <a className='d-flex flex-column relative' href={"https://insyncinsurance.youtrack.cloud"+file.url} target="_blank" rel="noopener noreferrer" onClick={(event)=>{
                                                    if (event.target.nodeName.toLowerCase() !== "a" && event.target.nodeName.toLowerCase() !== "img"){
                                                        event.preventDefault();
                                                    }
                                                    
                                                }} style={{
                                                    textDecoration: "none"
                                                }}>
                                                

                                                {(!ImageExtentions.includes(file.extension.toLowerCase()) || !file.thumbnailURL ?

                                                    <span className={'px-2 py-1 mb-3 mx-auto rounded-2 text-white '+(!ImageExtentions.includes(file.extension) || !file.thumbnailURL ? "no-thumbnail" : "d-block")} style={{
                                                        display: "block",
                                                        width: "max-content",
                                                        background: "#62ab7e",
                                                        textDecoration: "none",
                                                        fontFamily: "arial"
                                                    }}>{file.extension.toUpperCase()}</span>

                                                    : <img src={"https://insyncinsurance.youtrack.cloud"+file.thumbnailURL} className="my-2 mx-0 shadow thumbnail cursor-pointer" style={{
                                                        width: 80,
                                                        height: 80,
                                                        objectFit: "cover",
                                                        objectPosition: "left"
                                                    }} alt={file.name}/>
                                                )}
                                                
                                                    <span className='mx-auto text-secondary'>{
                                                        (file.name.length > 27 ? file.name[0].toUpperCase()+(file.name.slice(1).split("."))[0].slice(0,27)+"..."
                                                        : file.name[0].toUpperCase()+(file.name.slice(1).split("."))[0])
                                                    }</span>

                                                    <button className="border-0 mx-auto strict-absolute-center" onClick={(event)=>{
                                                        downloadImage(file.base64Content, file.name)
                                                    }} style={{
                                                        display:"none",
                                                        marginTop: (ImageExtentions.includes(file.extension.toLowerCase()) ? 0 : 35)
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                            <path d="M2432 5099 c-71 -28 -144 -101 -171 -174 -21 -55 -21 -63 -21 -1318 l0 -1261 -308 306 c-356 355 -367 363 -507 363 -99 -1 -159 -26 -227 -92 -66 -65 -92 -128 -92 -228 -2 -149 -27 -118 662 -806 574 -574 610 -608 665 -628 38 -14 81 -21 127 -21 46 0 89 7 127 21 55 20 91 54 665 628 689 688 664 657 662 806 0 100 -26 163 -92 228 -68 66 -128 91 -227 92 -140 0 -151 -8 -507 -363 l-308 -306 0 1261 c0 1255 0 1263 -21 1318 -45 121 -161 195 -302 194 -47 0 -90 -7 -125 -20z"/>
                                                            <path d="M192 619 c-32 -13 -71 -39 -101 -69 -160 -160 -103 -452 104 -529 l56 -21 2309 0 2309 0 56 21 c260 97 260 501 0 598 l-56 21 -2312 -1 c-2299 0 -2312 0 -2365 -20z"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </a>
                                                
                                            </div>)

                                        return file;
                                    })

                                    return (Files.length > 0 ? Files : <i>No file attached</i>);
                                })()}
                            </div>

                        </div>
                    </div>
                ));

                // console.log(commentSection);
            } catch (error) {
                Sentry.captureException(error);
                setTimeout(()=>{
                    
                    if (document.getElementById("error-card")){
                        document.getElementById("error-card").className = "visible";
                    }
                    if (document.getElementById("loading")){
                        document.getElementById("loading").className = "invisible";
                    }
                    setData(null);
                }, 1000);

            }
        }
        
        fetchData();
          
    }, [issueID, setData, ACCESS_TOKEN, API_URL, commentsArray])

    function resolveIssue(issueID){

        try {
            
            let spinner = document.getElementById("resolve-spinner").classList;
            let reasonName = document.getElementById("reasonName").value;
            let reasonState = document.getElementById("reasonState").value;
            let reasonDesc = document.getElementById("reasonDesc").value;
            const REASON_YT_QUERY = `api/commands?fields=issues(id%2CidReadable)%2Cquery%2Cvisibility(permittedGroups(id%2Cname)%2CpermittedUsers(id%2Clogin))`;
            
            spinner.remove("d-none");
            spinner.add("d-block");
            
            if (reasonName && reasonDesc && reasonState){
                axios.post(`${BASE_URL}/api/commands?${REASON_YT_QUERY}`, {
                    "query": `State ${reasonState} Closed Reason ***\\[Resolved-By: ${reasonName}\\]*** - ${reasonDesc}`,
                    "issues": [
                        {
                            "idReadable": issueID
                        }
                    ],
                    "silent": true,
                    "visibility": {
                        "$type": "CommandLimitedVisibility",
                        "permittedGroups": [
                            {
                                "id": "3-7"
                            }
                        ]
                    }
                }, {
                    headers: {
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${ACCESS_TOKEN}`
                    }
                }
                ).then((response)=>{
                    // console.log(response)
                    window.location.reload()
                }).catch((error)=>{
                    Sentry.captureException(error);
                })
            }
            else {
                window.alert("Please complete the reason form!");
            }
        }
        catch(error){
            Sentry.captureException(error);
        }
    }

    function reOpenIssue(issueID){

        try {
            
            let spinner = document.getElementById("resolve-spinner").classList;

            spinner.remove("d-none");
            spinner.add("d-block");

            axios.post(`${API_URL}/${issueID}?${YT_QUERY}`, {
                "customFields": [{
                    "value": {
                        "isResolved": false,
                    //   "localizedName": None,
                        "name": "Reopened",
                        "$type": "StateBundleElement"
                    },
                    "name": "State",
                    "$type": "StateIssueCustomField"
                }]
            }, {
                headers: {
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ACCESS_TOKEN}`
                }
            }
            ).then((response)=>{
                // console.log(response)
                window.location.reload()
            }).catch((error)=>{
                Sentry.captureException(error);
            })
        }
        catch(error){
            Sentry.captureException(error);
        }
    }

    async function handleUpload(files, issueID, commentID) {

        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post(`${BASE_URL}/api/issues/${issueID}/comments/${commentID}/attachments`,
                formData, {
                    headers: { 
                      'Accept': 'application/json', 
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${ACCESS_TOKEN}`
                    },
                });

                if (response.status){
                    window.location.reload();
                }

            } catch (error) {
                console.error(error);
            }
        }
        setFileList([]);
    }

    const handleComment = (e) => {
        e.preventDefault();
        document.getElementById("comment-spinner").style.display = "block";
        
        handleClose();
        let comment = e.target.elements.comment.value;
        let username = e.target.elements.username.value;
        let fullComment = `${username}: ${comment}`;

        console.log(fullComment);

        axios.post(`${API_URL}/${issueID}/comments?fields=id,author(name),deleted,text,updated`, {
            issue_id: issueID,
            text: fullComment
        }, {
            headers: { 
              'Accept': 'application/json',
              'Authorization': `Bearer ${ACCESS_TOKEN}`
            },
        }).then((response) => {

            console.log(response, response.status === 200, fileList);

            if (response.status === 200 && fileList.length > 0){
                // console.log(fileList, issueID, response.data.id);
                handleUpload(fileList, issueID, response.data.id)
            }
            else {
                window.location.reload();
            }
        }
        ).catch((error) => {
            console.log(error);
        }
        );

    }
    function downloadImage(base64Url, fileName) {
        const link = document.createElement('a');
        link.href =base64Url;
        link.download = fileName;
        link.rel = "noopener noreferrer";
        link.click();
    }
    
    
    return(
        <main>
            <Navbar />

            {/* Modal */}
            <div className="modal fade" id="resolveIssueModal" tabIndex="-1" role="dialog" aria-labelledby="resolveIssueModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="resolveIssueModalLabel">Capture a reason</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                        <Form>

                            <FloatingLabel
                                controlId="reasonName"
                                label={<div className="label-content">Your Name <span className="red-asterisk">*</span></div>}
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Your Name"  name="reasonName" required={true}/>
                            </FloatingLabel>

                            <FloatingLabel controlId="reasonDesc" label={<div className="label-content" style={{paddingTop:0, paddingBottom:0}}>Reason for closing this issue? <span className="red-asterisk">*</span></div>} style={{border: "1px solid #dee2e6", borderRadius:8}}>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Provide a detailed description of the issue"
                                    style={{ height: '100px', boxShadow: "none", border:0, position: "relative" }}
                                    name="reasonDesc"
                                    required={true}
                                />
                            </FloatingLabel>

                            <FloatingLabel controlId="reasonState" label={<div className="label-content">State <span className="red-asterisk">*</span></div>} className='w-50 mt-3'>
                                <Form.Select aria-label="Select state" name="reasonState" required={true} className='cursor-pointer' >
                                    <option>Done</option>
                                    <option>No fix required</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Form>
                        <p></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={()=>resolveIssue(data.id)}>Resolve issue</button>
                    </div>
                    </div>
                </div>
            </div>

            {/* Resolve note */}
            <div className="modal fade" id="resolveNoteModal" tabIndex="-1" role="dialog" aria-labelledby="resolveNoteModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="resolveNoteModalLabel">Reason for resolve</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* Display reason for change here */}

                        <ReactMarkdown>{data && "closed reason" in data ? data["closed reason"] : "No reason found!"}</ReactMarkdown>

                        <p></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
            
            <section className="container d-flex flex-row justify-content-center h-auto align-items-center full-responsive-width">
                { data ? (
                    <section className="full-responsive-width">
                        <br/>
                        <div className="section-title text-center px-5 py-2">
                            <h1>{data.summary}</h1>
                        </div>
                        
                        <p>&nbsp;</p>

                        <div className="m-5 h-auto justify-content-center">
                            <div className="table-responsive margin-bottom-2x">

                                <div className='d-flex issue-box me-3'>

                                    
                                    <div className='d-flex bg-danger text-white p-2' style={{
                                            minWidth: 80,
                                            minHeight: 80,
                                            alignContent: "center",
                                            borderRadius: "50%"
                                        }}>
                                        <h2 className='d-block m-auto' style={{fontSize: "2rem"}}>
                                            {
                                                (()=>{
                                                    var requester = data.requester ? data.requester : data.initiator;
                                                    
                                                    if (requester && requester !== "Unassigned"){
                                                        let requesterName = requester.split(" ");
                                                        return (requesterName.length > 1 ? requester.split(" ")[0][0].toUpperCase()+requester.split(" ")[1][0].toUpperCase() : requesterName[0][0].toUpperCase());

                                                    }
                                                    else {
                                                        return "IT";
                                                    }
                                                })()
                                            }
                                        </h2>
                                    </div>
                                    
                                    <div className='d-flex flex-column'>
                                        <h3 className='my-auto mx-3 mb-0'>
                                            {
                                                (()=>{

                                                    if (data.requester && data.requester !== "Unassigned"){
                                                        return (data.requester ? data.requester[0].toUpperCase()+data.requester.slice(1) : data.initiator[0].toUpperCase()+data.initiator.slice(1))
                                                    }
                                                    else {
                                                        return "Internal Ticket"
                                                    }
                                                })()
                                            }
                                        </h3>
                                        <small className='d-block mx-3 mb-auto'>{new Date(data["created"]).toDateString()}</small>
                                    </div>

                                    <div className='d-block my-auto ms-auto me-0 relative'>
                                        {
                                            !data.isResolved ?
                                            <button className='d-block my-auto btn bg-primary text-white relative ws-nowrap border-0 rounded-3' data-toggle="modal" data-target="#resolveIssueModal">
                                                <span className='absolute'><Spinner id='resolve-spinner' className='d-none' animation="grow" variant="white"/></span> Resolve issue</button>
                                            :
                                            <>
                                                <button id='resolve-note' className='d-block my-auto btn text-white relative ws-nowrap border-0 rounded-3' data-toggle="modal" data-target="#resolveNoteModal">
                                                    View resolve note</button>

                                                <button className='d-block my-auto btn bg-success text-white relative ws-nowrap border-0 rounded-3' onClick={()=>reOpenIssue(data.id)}>
                                                    <span className='absolute'><Spinner id='resolve-spinner' className='d-none' animation="grow" variant="white"/></span> Reopen issue</button>
                                            </>
                                        }
                                    </div>
                                </div>
                                
                                <p>&emsp;</p>

                                <div className='d-flex flex-row-reverse'>

                                    <div id='issue-status' className='px-3 pb-4'>
                                        <table>
                                            <thead>
                                                <tr><th>Issue Status</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Project</td><td>{data.project}</td>
                                                </tr>
                                                <tr>
                                                    <td>Priority</td><td><Badge bg={priorityColours[data.priority]}><span className={priorityColours[data.priority]}>{data.priority}</span></Badge></td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td><td><Badge bg={stateColours[data.state]}><span className={stateColours[data.state]}>{data.state}</span></Badge></td>
                                                </tr>
                                                <tr>
                                                    <td>Reporter</td><td>{data.requester ? data.requester : data.initiator}</td>
                                                </tr>
                                                <tr>
                                                    <td>Assignees</td><td>{Array.isArray(data.assignee) ?
                                                    (data.assignee.length > 0 ? data.assignee.map(x => x.name).join(', ')
                                                    : "Unassigned" ) : data.assignee}</td>
                                                </tr>
                                                <tr>
                                                    <td>Type</td><td>{data.type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Estimation</td><td>{(data["estimation"] ? data["estimation"].toLowerCase().replace("p","").replace("t"," ").replace("w","w ").replace("h","h ").trim() : "Not set")}</td>
                                                </tr>
                                                <tr>
                                                    <td>Spent Time</td><td>{(data["spent time"] ? data["spent time"].toLowerCase().replace("p","").replace("t"," ").replace("w","w ").replace("h","h ").trim() : "Not set")}</td>
                                                </tr>
                                                <tr>
                                                    <td>Created</td><td>{new Date(data.created).toDateString()}</td>
                                                </tr>
                                                <tr>
                                                    <td>Last updated</td><td>{new Date(data.updated).toDateString()}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>Files</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        {(()=>{
                                                            const Files = [];
                                                            const ImageExtentions = ["png","jpg","jpeg","gif"];

                                                            data.attachments.map((file, i)=>{
                                                                
                                                                    Files.push(<div className='d-block float-left relative p-3 m-2' key={i} style={{
                                                                        background: (file.extension !== null ? (ImageExtentions.includes(file.extension.toLowerCase()) ? "#e7efff" : "#e5f6e3") : "#e5f6e3")
                                                                    }}>

                                                                        <a className='d-flex flex-column relative' href={"https://insyncinsurance.youtrack.cloud"+file.url} target="_blank" rel="noopener noreferrer" onClick={(event)=>{
                                                                            if (event.target.nodeName.toLowerCase() !== "a" && event.target.nodeName.toLowerCase() !== "img"){
                                                                                event.preventDefault();
                                                                            }
                                                                            
                                                                        }} style={{
                                                                            textDecoration: "none"
                                                                        }}>
                                                                        
                                                                        {(()=>{
                                                                            if (!file.extension){
                                                                                window.fileExt = file.name.split('.');
                                                                                window.fileExt = (window.fileExt.length > 0 ? window.fileExt[window.fileExt.length - 1] : "Unknown file")
                                                                            }
                                                                        })()}
                                                                        
                                                                        {(!file.extension || (file.extension && !ImageExtentions.includes(file.extension.toLowerCase())) || !file.thumbnailURL ?
                    
                                                                            <span className={'px-2 py-1 mb-3 mx-auto rounded-2 text-white '+(!file.extension || (file.extension && !ImageExtentions.includes(file.extension)) || !file.thumbnailURL ? "no-thumbnail" : "d-block")} style={{
                                                                                display: "block",
                                                                                width: "max-content",
                                                                                background: "#62ab7e",
                                                                                textDecoration: "none",
                                                                                fontFamily: "arial"
                                                                            }}>{(file.extension ? file.extension.toUpperCase() : window.fileExt)}</span>

                                                                            : <img src={"https://insyncinsurance.youtrack.cloud"+file.thumbnailURL} className="my-2 mx-0 shadow thumbnail cursor-pointer" style={{
                                                                                width: 80,
                                                                                height: 80,
                                                                                objectFit: "cover",
                                                                                objectPosition: "left"
                                                                            }} alt={file.name}/>
                                                                        )}
                                                                        
                                                                            <span className='mx-auto text-secondary'>{
                                                                                (file.name.length > 27 ? file.name[0].toUpperCase()+(file.name.slice(1).split("."))[0].slice(0,27)+"..."
                                                                                : file.name[0].toUpperCase()+(file.name.slice(1).split("."))[0])
                                                                            }</span>

                                                                            <button className="border-0 mx-auto strict-absolute-center" onClick={(event)=>{
                                                                                downloadImage(file.base64Content, file.name)
                                                                            }} style={{
                                                                                display:"none",
                                                                                marginTop: (file.extension && ImageExtentions.includes(file.extension.toLowerCase()) ? 0 : 35)
                                                                            }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                                                    <path d="M2432 5099 c-71 -28 -144 -101 -171 -174 -21 -55 -21 -63 -21 -1318 l0 -1261 -308 306 c-356 355 -367 363 -507 363 -99 -1 -159 -26 -227 -92 -66 -65 -92 -128 -92 -228 -2 -149 -27 -118 662 -806 574 -574 610 -608 665 -628 38 -14 81 -21 127 -21 46 0 89 7 127 21 55 20 91 54 665 628 689 688 664 657 662 806 0 100 -26 163 -92 228 -68 66 -128 91 -227 92 -140 0 -151 -8 -507 -363 l-308 -306 0 1261 c0 1255 0 1263 -21 1318 -45 121 -161 195 -302 194 -47 0 -90 -7 -125 -20z"/>
                                                                                    <path d="M192 619 c-32 -13 -71 -39 -101 -69 -160 -160 -103 -452 104 -529 l56 -21 2309 0 2309 0 56 21 c260 97 260 501 0 598 l-56 21 -2312 -1 c-2299 0 -2312 0 -2365 -20z"/>
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        </a>
                                                                        
                                                                    </div>)

                                                                    return file;
                                                            })

                                                            return (Files.length > 0 ? Files : <i>No file attached</i>);
                                                        })()}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='w-100'>
                                        <table className="table margin-bottom-none">
                                            <thead>
                                            <tr>
                                                <th>Ticket ID</th>
                                                <th>Ticket Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{issueID.toUpperCase()}</td>
                                                <td><ReactMarkdown>{data.description}</ReactMarkdown></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div id="comment-spinner" className='overlayer'>
                            <Spinner id='comment-spinner' className='d-block mx-auto relative' animation="grow" variant="white" style={{
                                top: "15%",
                                transform: "scale(4)"
                            }}/>
                        </div>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Post Comment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body><form className="justify-content-center" onSubmit={handleComment}>
                                <div className="form-group">
                                <textarea className="form-control form-control-rounded" id="commentText" rows="8"
                                          placeholder="Write your message here..." name="comment" onBlur={(event)=>{
                                            if (event.target.value.trim() === "") event.target.value = "";
                                        }} required></textarea>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="commentText">Your Name</label>
                                    <input className="form-control form-control-rounded" id="commentAuthor" type="text"
                                            placeholder="Jane Doe" required name="username" onBlur={(event)=>{
                                                if (event.target.value.trim() === "") event.target.value = "";
                                            }}></input>
                                </div>
                                <br/>
                                        
                                <div className="form-group">
                                    <label htmlFor="related-files">Attach Files</label>
                                    <br/>
                                    <section className="dropzone-container">
                                        <div style={baseStyle} {...getRootProps({className: 'dropzone'})}>
                                            <input id='cfInput' {...getInputProps()} />
                                            <p>Drop files here, or click to select</p>
                                        </div>
                                        <aside className='mt-3'>
                                            <ul className='ps-0'>{files}</ul>
                                        </aside>
                                    </section>
                                </div>
                                
                                <div className="text-right">
                                    <button className="btn btn-primary" type="submit">Comment</button>
                                </div>
                            </form></Modal.Body>
                        </Modal>

                        <div className=" m-5 pb-5 h-auto justify-content-center">
                            <div className="">
                                        <span className="rounded-0"></span>
                                        <h1 className="card-title" >Comments</h1>
                                        <br/>
                                <Button variant="primary" onClick={handleShow}>
                                    Post Comment
                                </Button>
                                        <br/>
                                        <br/>
                                        {commentSection.length > 0 ? commentSection : "No Comments Yet"}
                            </div>
                        </div>
                    </section>
                    ) : (
                    <section>
                        <div id="loading" className="visible text-center flex-column m-lg-5">
                            <Spinner animation="grow" variant="warning"/>
                        </div>
                        <section id="error-card" className="container-fluid d-flex flex-row justify-content-center h-auto align-items-center invisible">
                            <div className="m-5 pb-5 h-auto justify-content-center p-5">
                                <div className="">
                                    <div className="card" >
                                        <div className="card-body text-center flex-column">
                                            <span className="rounded-0"></span>
                                            <h1 className="card-title" >Ticket not found!</h1>
                                            <p className="card-text" >Sorry. The ticket, {issueID}, could not be accessed. It may not exist or you may not have permission to view it.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                )}

            </section>

            <Footer />
        </main>
    )
}