import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Profile() {
    return (
        <div>
            <Navbar />
            <Container className="mt-5">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <h1 className="text-center mb-4" style={{ fontSize: '2.5em', color: '#4a4a4a' }}>Profile</h1>
                        <Row>
                            <Col md={4}>
                                <Card style={{ width: '100px', height: '100px' }}>
                                    <Card.Img 
                                        variant="top" 
                                        src="https://insyncinsurance.youtrack.cloud/hub/api/rest/avatar/5a53e2e2-1d92-4c1d-befd-b33b98ea1586?s=48" 
                                        alt="User Avatar"
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> 
                                </Card>
                            </Col>
                            <Col md={8}>
                                <Card>
                                    <Card.Body>
                                        <Form>
                                            <Form.Group controlId="formName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" readOnly defaultValue="Insync Reporter" />
                                            </Form.Group>
                                            <Form.Group controlId="formEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" readOnly defaultValue="iceadmin@insyncinsurance.co.uk" />
                                            </Form.Group>
                                            <Form.Group controlId="formTimezone">
                                                <Form.Label>Timezone</Form.Label>
                                                <Form.Control type="text" readOnly defaultValue="British Summer Time (UTC/GMT +1 hours)" />
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}
