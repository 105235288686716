import React, {useEffect} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate} from "react-router-dom";

// const VERSION_STRING = `/v${process.env.REACT_APP_TICKET_VERSION}`;

export default function Thanks() {
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!state) {
            navigate("/");
        }
    }, [state, navigate]);
    if (!state) {
        return null;
    }


    return(
        <main>
            <Navbar />

            <section className="container-fluid d-flex flex-row justify-content-center h-auto align-items-center">
                <div className=" my-5 mx-auto pb-5 h-auto justify-content-center" style={{
                    minWidth:"350px",
                    maxWidth: "100%",
                    width: "max-content"
                }}>
                    <div className="">
                
                        <a href='https://insyncinsurance.sharepoint.com/sites/ComplaintsandBreaches' style={{
                            textDecoration: "none",
                            fontSize: "1rem"
                        }} target='_blank' rel="noreferrer">
                            <span className='d-block alert alert-warning my-3'>If the issue raised is a breach, please report the breach to the <ins>Q&C team here</ins>.</span>
                            
                        </a>

                        <div className="card" >
                            <div className="card-body text-center flex-column">

                                <h1 className="card-title" >Submission complete!</h1>
                                <p className="card-text" >Thanks for your submission.</p>
                                <p className="card-text" >Your submission ID is: {state.readableID}</p>
                                <p className="card-text" >Your submission summary
                                    is: {state.title}</p>
                                <p className="card-text" > We will be in touch shortly.</p>

                                <a href={"/submit-issue"} className="btn btn-primary my-3">Submit another ticket!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    )
}