import React, { useEffect } from "react";
import "./css/styles.css";
import "./css/sidebar.css";
import "./css/mobile_sidebar.css";
import { Table, Form, Row, Col, Button } from 'react-bootstrap';

const SideBar = (props)=>{
    
    const resolvedStates = ['Done', 'Duplicate', 'No fix required', 'Verified', 'Incomplete', 'Solved', 'Won\'t fix', 'Cannot Fix', 'Can\'t Reproduce'];

    const closedStates = ['Done', 'No fix required', 'Solved', 'No fix required', 'Won\'t fix'];

    const filteredData = props.tickets.getData()
    .filter(props.filters.filterResolved)
    .filter(props.filters.filterByRequesterOrReporter)
    .filter(props.filters.filterByProject)
    .filter(props.filters.filterByProjectState);
    
    const demoData = props.tickets.getData();
    const projectStates = props.states.projectStates;
    

    // open tickets by requesters
    const openRequesterFiltered = props.tickets.getData().filter((ticket) => {
        const requesterField = ticket.customFields.find(
          (field) => field.projectCustomField.field.name === 'Requester'
        )?.value;
      
        const reporterField = ticket.customFields.find(
          (field) => field.projectCustomField.field.name === 'Initiator'
        )?.value;
      
        if (!props.requester || props.requester === "") {
          return true;
        } else {
          return (requesterField?.toLowerCase().includes(props.requester.toLowerCase())) || 
                 (reporterField?.toLowerCase().includes(props.requester.toLowerCase()));
        }
    }).filter((ticket) => {
        return ticket.state === "Open";
    }).length

    // awaiting tickets by requesters
    const awaitingRequesterFiltered = props.tickets.getData().filter((ticket) => {
        const requesterField = ticket.customFields.find(
          (field) => field.projectCustomField.field.name === 'Requester'
        )?.value;
      
        const reporterField = ticket.customFields.find(
          (field) => field.projectCustomField.field.name === 'Initiator'
        )?.value;
      
        if (!props.requester || props.requester === "") {
          return true;
        } else {
          return (requesterField?.toLowerCase().includes(props.requester.toLowerCase())) || 
                 (reporterField?.toLowerCase().includes(props.requester.toLowerCase()));
        }
    }).filter((ticket) => {
        return ticket.state === "Awaiting Reply";
    }).length


    // Tickets to handle by requesters
    // const pendingRequesterFiltered = props.tickets.getData().filter((ticket) => {
    //     const requesterField = ticket.customFields.find(
    //       (field) => field.projectCustomField.field.name === 'Requester'
    //     )?.value;
      
    //     const reporterField = ticket.customFields.find(
    //       (field) => field.projectCustomField.field.name === 'Initiator'
    //     )?.value;
      
    //     if (!props.requester || props.requester === "") {
    //       return true;
    //     } else {
    //       return (requesterField?.toLowerCase().includes(props.requester.toLowerCase())) || 
    //              (reporterField?.toLowerCase().includes(props.requester.toLowerCase()));
    //     }
    // }).filter((ticket) => {
    //     return !resolvedStates.includes(ticket.state)
    // }).length;


    // All resolved tickets
    const resolvedIssues = props.tickets.getData().filter((ticket) => {
        return resolvedStates.includes(ticket.state)
      }).length;

    // All pending tickets
    const pendingIssues = props.tickets.getData().filter((ticket) => {
        return !resolvedStates.includes(ticket.state)
      }).length;

    // All closed tickets
    const closedIssues = props.tickets.getData().filter((ticket) => {
        return closedStates.includes(ticket.state)
      }).length;

    // All Open tickets
    const openStates = props.tickets.getData().filter((ticket) => {
        return ticket.state === "Open";
      }).length;
    
    // All tickets on hold
    const onHoldStates = props.tickets.getData().filter((ticket) => {
        return ticket.state === "On Hold";
      }).length;


    useEffect(()=>{
        window.onscroll = ()=>{
            if (document.querySelector('#sidebar')){
                if (document.querySelector('html').scrollTop >= document.querySelector('header').clientHeight){
                    document.querySelector('#sidebar').classList.add('absolute', 'fixed', 'top', 'h-100');
                    document.querySelector('footer').style.marginLeft = "300px !important";
                }
                else {
                    document.querySelector('#sidebar').classList.remove('absolute', 'fixed', 'top', 'h-100');
                    document.querySelector('footer').style.marginLeft = "0px !important";
                }
            }
        }
    }, []);


    return (
        <>
            <div id="sidebar" className="w-100 p-4" {...props}>
                <div className="d-flex">
                    <h1 style={{fontSize:"1.5rem", textTransform: "capitalize"}}>Tickets</h1>
                    <a href="/submit-issue" className="d-block ms-auto"><button className="btn bg-primary text-white"><i className="far fa-plus"></i> New ticket</button></a>
                </div>
                <div className="d-flex flex-column mt-5">
                    <div className="w-100 my-2"><span>Tickets to handle</span> {props.requester?<span>{awaitingRequesterFiltered}</span>:""}</div>
                    <div className="w-100 my-2"><span>My open tickets</span> {props.requester?<span>{openRequesterFiltered}</span>:""}</div>
                </div>
                
                {/* Mobile Filters */}
                
                <Row id="filter" className="justify-content-md-left w-100 mx-0">
                  <Col md="0" className="w-100 mx-0 px-0">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Filters - ({filteredData.length} of {demoData.length})</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <td>
                            <Form.Check 
                              type="checkbox" 
                              label="Show Resolved"
                              checked={props.states.showResolved}
                              onChange={e => props.func.setShowResolved(e.target.checked)}
                            />
                          </td>
                        </tr>
                        
                        <tr>
                          <td>
                            <Form.Control 
                              type="text" 
                              value={props.requester}
                              onChange={(e) =>{
                                props.func.setPage(0)
                                props.func.setCurrentPageNumber(1)
                                props.func.setRequester(e.target.value)
                              }}
                              placeholder="Enter requester"
                            />
                          </td>
                        </tr>
                        
                        <tr>
                          <td>
                            <Form.Select 
                              value={props.states.project}
                              onChange={(e) => {
                                props.func.setPage(0)
                                props.func.setCurrentPageNumber(1)
                                props.func.setProject(e.target.value)
                              }}
                            >
                                <option value="">Select project</option>
                                {props.func.getUniqueProjects().map((projectName, index) => (
                                <option key={index} value={projectName}>{projectName}</option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        
                        <tr>
                          <td>
                            <Form.Select 
                              value={props.states.projectState}
                              onChange={(e) => {
                                props.func.setPage(0)
                                props.func.setCurrentPageNumber(1)
                                props.func.setProjectState(e.target.value)
                              }}
                            >
                                <option value="">Select state</option>
                                {projectStates.map((state, index) => (
                                <option key={index} value={state}>{state}</option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        
                        <tr>
                          <td>
                            <Button onClick={props.func.clearFilters} variant="secondary" className="mt-4 ws-nowrap">Clear All Filters</Button>
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                  </Col>
                </Row>

                {/* My Views */}
                {/* <div className="mt-4 mb-0">
                    <h1 className="label">My Views</h1>
                </div>
                <div className="d-flex flex-column mt-2">
                    <div className="w-100 my-2"><span>My tickets in last 7 days</span> <span>0</span></div>
                    <div className="w-100 my-2"><span>My open tickets</span> <span>4</span></div>
                </div> */}

                {/* Statuses */}
                <div className="mt-4 mb-0">
                    <h1 className="label">ALL STATUSES</h1>
                </div>
                <div className="d-flex flex-column mt-2">
                    <div className="w-100 my-2"><span>Open</span> <span>{openStates}</span></div>
                    <div className="w-100 my-2"><span>Pending</span> <span>{pendingIssues}</span></div>
                    <div className="w-100 my-2"><span>On hold</span> <span>{onHoldStates}</span></div>
                    <div className="w-100 my-2"><span>Solved</span> <span>{resolvedIssues}</span></div>
                    <div className="w-100 my-2"><span>Closed</span> <span>{closedIssues}</span></div>
                    <div className="w-100 my-2"><span>Spam</span></div>
                </div>
            </div>
        </>
    )
}

const Func = (props)=>{
  return <SideBar {...props}/>
}

export default Func;