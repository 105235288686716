import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactMarkdown from "react-markdown";
import "./css/knowledgeBase.css";
import defaultContent from "./resources/knowledgeData";
import { Grid } from 'react-loader-spinner';
import * as Sentry from "@sentry/react";

export default function KnowledgeBase() {
  const API_URL =
    "https://insyncinsurance.youtrack.cloud/api/articles?fields=project(name),summary,content";
  const API_KEY = process.env.REACT_APP_ACCESS_TOKEN;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState("");


  useEffect(() => {
        
      const retrieveContent = () => {
        return fetch(API_URL, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${API_KEY}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data.map((item) => {
              return {
                title: item.summary,
                content: item.content,
                project: item.project.name,
              };
            });
          })
          .catch((error) => {
            Sentry.captureException(error);
          });
      };

      retrieveContent().then((fetchedData) => setData(fetchedData));

  }, [API_KEY, API_URL]);

  const [selectedContent, setSelectedContent] = useState(defaultContent);

  const handleItemClick = (content, project) => {
    setSearchResult("");
    setSearchTerm("");
    setSelectedContent(content);
    setProject(project);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // match search term with item.project and only display the project that matches
    const result = data.filter((item) => {
      return item.project.toLowerCase().includes(searchTerm.toLowerCase());
    }
    );
    if (result.length === 0) {
      setSearchResult("No results found");
      setProject("");
    }
    else {
      setSearchResult(result[0].content);
      setProject(result[0].project);
    }
    setIsLoading(false);


  };

  function onTextAreaEnterPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSearch(e);
    }
  }

  return (
    <main>
      <Navbar />

      <section className="container my-5">
        <div className="knowledge-base-container">
          <div className="input-group mb-3 position-relative">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="What would you like to know?"
              aria-label="Search"
              aria-describedby="button-addon2"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={onTextAreaEnterPress}
              disabled={isLoading}
            />
            <Grid
                height="20"
                width="20"
                color="#FF8C00"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{"position": "absolute", "top": "25%", "right": "20px", "zIndex": "10"}}
                wrapperClass=""
                visible={isLoading}
            />
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="list-group">
                {data.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => handleItemClick(item.content, item.project)}
                    className="list-group-item list-group-item-action"
                  >
                    {item.title}
                    <span className="badge bg-secondary ms-2">
                      {item.project}
                    </span>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  {/* The project pill at the top right of the body */}
                  <span className="badge bg-secondary float-end">
                    {project}
                  </span>
                  <ReactMarkdown>
                    {searchResult ? searchResult : selectedContent}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}