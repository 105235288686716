import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate, useParams} from "react-router-dom";
import MobileMenu from './MobileMenu';
// import Banner from "./Banner";
import { useState } from 'react';
import axios from "axios";
import { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import "./css/navbar.css";
import * as Sentry from "@sentry/react";

const VERSION_STRING = `v${process.env.REACT_APP_TICKET_VERSION}`;

function Navbar(props) {
    // if (!props.params.version){
    //     window.location.href = `${VERSION_STRING}${window.location.pathname}`;
    // }
    // else if ('/'+props.params.version !== VERSION_STRING){
    //     window.location.href =  VERSION_STRING;
    // }

    const navigate = useNavigate();
    const [isAdminLoggedIn, setAdminLogin] = useState(false);
    const REDIRECT_URI = "http://127.0.0.1:3000/admin";
    const youtrackUrl = 'https://insyncinsurance.youtrack.cloud';


    const handleSearch = (e) => {
        e.preventDefault();
        const query = e.target.elements.search.value;

        navigate(`/existing/${query}`);

    }

    useEffect(()=>{
        // add versioning for html stylesheet elements
        document.querySelectorAll('link').forEach((cssLink)=>{
            if (cssLink.rel === 'stylesheet' && !cssLink.href.includes('?version')){
                cssLink.href = cssLink.href+'?version='+VERSION_STRING;
            }
        })
        // add versioning for html javascript elements
        document.querySelectorAll('script').forEach((jsScript)=>{
            if (!jsScript.src.includes('?version')){
                jsScript.src = jsScript.src+'?version='+VERSION_STRING;
            }
        })

        if ("accessToken" in window.localStorage){
            
            let accessToken = window.localStorage.accessToken;
            let userID = window.localStorage.userID;

            // console.log(accessToken);

            // validate accessToken
            (async ()=>{

                await axios.get('https://insyncinsurance.youtrack.cloud/hub/api/rest/users/'+userID, {
                    headers: {
                        'Accept': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    }
                })
                .then((response) => {
                    if (response.status === 200){
                        setAdminLogin(true);
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    setAdminLogin(false);
                })

            })()
        }
        else {
            setAdminLogin(false);
        }
            
        // console.log(isAdminLoggedIn, localStorage)

    }, [isAdminLoggedIn, setAdminLogin]);


    function handleLogout(){
        
        axios.post(`${youtrackUrl}/hub/api/rest/oauth2/interactive/login?response_type=token&client_id=${process.env.REACT_APP_YOUTRACK_SERVICE_ID}&redirect_uri=${REDIRECT_URI}&scope=${process.env.REACT_APP_YOUTRACK_SERVICE_ID}%20Upsource%20TeamCity%20YouTrack%2520Slack%2520Integration%200-0-0-0-0&request_credentials=required`, {
            username: '',
            password: ''
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.accessToken}`,
            }
        })
        .then((response) => {
            localStorage.clear();
            window.location.href = '/admin'
        })
        .catch((error) => {
            Sentry.captureException(error);
            localStorage.clear();
            window.location.href = '/admin'
        })

    }

    return(
        <header className='d-flex flex-column mb-3'>
            <nav className="navbar navbar-expand-sm navbar-dark justify-content-center">
                <div className="">
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className="nav-link active" aria-current="page" href={"/"}>HOME</a>
                            <a className="nav-link" href={"/submit-issue"}>SUBMIT TICKET</a>
                            <a className="nav-link" href={"/knowledge-base"}>KNOWLEDGE BASE</a>
                            <a rel="noopener noreferrer" className="nav-link" href={"/issues"}>ALL ISSUES</a>
                        </div>

                        <div className="">
                            <form className="d-flex ms-5" onSubmit={handleSearch}>
                                <input className="form-control me-2" name="search" type="search" placeholder="Search" aria-label="Search"/>
                                <button id="search-btn" className="btn btn-outline-success" type="submit">Search</button>
                            </form>
                        </div>
                    </div>
                </div>
                
                {isAdminLoggedIn ? <button className='btn no-bg text-white relative my-2 mx-5' style={{
                    float: "right",
                    clear: "both",
                    // right:30,
                    border: "2px solid #505050"
                }} onClick={handleLogout}>Logout</button> : ""}
                
                 <Dropdown>
                    <Dropdown.Toggle variant="none" className='ms-3 no-shadow border-0 p-1 ms-4'>
                        <img src="https://insyncinsurance.youtrack.cloud/hub/api/rest/avatar/5a53e2e2-1d92-4c1d-befd-b33b98ea1586?s=48" alt="insync reporter" style={{
                            width: 40,
                            height: 40
                        }}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                        {/* <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                        <Dropdown.Item href="/logout">Logout</Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </nav>
            
            <div className="line"></div>

            {/* Mobile menu button */}
            <MobileMenu isAdminLoggedIn={isAdminLoggedIn}/>

            {/* {isAdminLoggedIn === true ? <Banner/> : ""} */}
            
        </header>
    );
}

function Func(props){
    return <Navbar {...props} params={useParams()}/>
}

// export default Home;
export default Func;