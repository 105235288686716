import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import SubmitIssue from "./components/SubmitIssueRevised";
import Thanks from "./components/Thanks";
import ChangeRequest from "./components/ChangeRequest";
// import RefundRequest from "./components/RefundRequest";
// import AccountsEnquiry from "./components/AccountsEnquiry";
// import InternalSystemRequest from "./components/InternalSystemRequest";
import Existing from "./components/Existing";
import KnowledgeBase from "./components/KnowledgeBase";
import AllIssues from "./components/AllIssues";
// import Admin from "./components/Admin";
import React from 'react';
import Profile from "./components/Profile";
import * as Sentry from "@sentry/react";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/admin" element={<Admin />} /> */}
          <Route path="/submit-issue" element={<SubmitIssue />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/change-request" element={<ChangeRequest />} />
          {/* <Route path="/refund-request" element={<RefundRequest />} /> */}
          {/* <Route path="/accounts-enquiry" element={<AccountsEnquiry />} /> */}
          {/* <Route path="/internal-system-request" element={<InternalSystemRequest />} /> */}
          <Route path="/knowledge-base" element={<KnowledgeBase />} />
          <Route path="/existing/:issueID" element={<Existing />} />
          <Route path="/issues" element={<AllIssues />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
  );
}

export default Sentry.withProfiler(App)