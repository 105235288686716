import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/styles.css";

// const VERSION_STRING = `/v${process.env.REACT_APP_TICKET_VERSION}`;

export default function Home() {
    return(
        <main>
            <Navbar/>
            <section className="pt-4 pt-md-11">
                <div className="container">
                    <div className="row align-items-center w-100 mx-auto">
                        <div className="col-12 col-md-5 col-lg-6 order-md-2">

                            <img src="/homepage image.jpg"
                                 className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
                                 alt="homepage" data-aos="fade-up" data-aos-delay="100"/>

                        </div>
                        <div className="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate" data-aos="fade-up">

                            <h1 className="display-3 text-center text-md-start">
                                Welcome to <span className="text">Insync</span> <br/>
                                Help Desk 
                            </h1>
                            <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">
                We are here to meet your needs. Please select from one of the below to log a new ticket or change request.
              </p> 
                            

                            <div className="text-center text-md-start">
                                <a id="btn" href={"/submit-issue"} className="btn btn-primary shadow lift me-1 mt-3">
                                    New Support Ticket <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                                </a>
                                <a id="btn" href={"/change-request"} className="btn btn-primary shadow lift me-1 mt-3">
                                    New Change Request <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                                </a>
                                {/* <a id="btn" href={"/refund-request"} className="btn btn-primary shadow lift me-1 mt-3">
                                    New Refund Request <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                                </a>
                                <a id="btn" href={"/accounts-enquiry"} className="btn btn-primary shadow lift me-1 mt-3">
                                    New Accounts Enquiry <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                                </a>
                                <a id="btn" href={"/internal-system-request"} className="btn btn-primary shadow lift me-1 mt-3">
                                Internal Systems Request <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                                </a> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </main>
    )
}